<template>
  <div class="order-card-container">
    <span class="order-header">Payment Details</span>
    <b-form-radio-group
      @change="changePaymentSelect"
      class="mt-2 ml-3"
      v-model="selected"
    >
      <!-- <b-row>
        <b-form-radio value="paytm" class="mt-3" name="radio-size" size="lg">
          <h6 style="margin-top: 6px; font-size: 18px">Paytm</h6>
        </b-form-radio>
      </b-row> -->
      <b-container v-if="partnerInfo.online">
        <b-row>
          <b-form-radio
            value="CASHFREE"
            v-model="$store.state['checkout'].paymentMethodClick"
            class="mt-3"
            name="radio-size"
            size="lg"
          >
            <h6 style="margin-top: -1px; font-size: 20px">Cashfree</h6>
          </b-form-radio>
        </b-row>
      </b-container>
      <b-container v-if="partnerInfo.cod">
        <b-row>
          <b-form-radio
            v-model="$store.state['checkout'].paymentMethodClick"
            value="COD"
            class="mt-3"
            name="radio-size"
            size="lg"
          >
            <h6 style="margin-top: -1px; font-size: 20px">COD</h6>
          </b-form-radio>
        </b-row>
      </b-container>
    </b-form-radio-group>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "cart-payment",
  data() {
    return {
      selected: "COD",
    };
  },
  computed: {
    ...mapState({
      paymentMethodClick: state => state.checkout.paymentMethodClick,
      partnerInfo: state => state.checkout.partnerInfo,
    }),
  },

  methods: {
    async changePaymentSelect(res) {
      await this.$store.dispatch("checkout/checkoutFinal");
      this.selected = res;
      console.log(res);
    },
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  mounted() {
    if (this.partnerInfo.cod) {
      this.selected = "COD";
    }
  },
};
</script>
<style scoped>
.order-left {
  left: 150px;
}

.order-card-container {
  width: 80%;
}

.order-header {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 39px;
  color: #000000;
}

.list-group {
  margin-top: 15px;
  max-height: 540px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
</style>
